import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicSafeString, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonButton, IonIcon, IonSegment, IonSegmentButton, IonProgressBar, IonContent, IonList, IonItem, IonLabel } from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { MapComponent } from '@shared/components/map/map';
import { CoordsModel } from '@shared/models/coords.model';
import { PlaceModel } from '@shared/models/place.model';
import { MicrostoreApiResource } from '@shared/resources/api/microstore-api.resource';
import { GoogleMapsService } from '@shared/services/google-maps.service';
import { LocationService } from '@shared/services/location.service';
import { getDistanceMi } from '@shared/utils';
import { finalize, tap } from 'rxjs';

import { PlaceModalComponent } from './place/place';
import { addIcons } from "ionicons";
import { informationCircle, pin, locate } from "ionicons/icons";

const miamiCoords: CoordsModel = {
    latitude: 25.7616798,
    longitude: -80.1917902,
};
@Component({
    selector: 'places',
    standalone: true,
    imports: [
        CommonModule,
        PlaceModalComponent,
        MapComponent,
        FormsModule,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonTitle,
        IonButton,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonProgressBar,
        IonContent,
        IonList,
        IonItem,
        IonLabel
    ],
    templateUrl: 'places.html',
    styleUrls: ['places.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesPage implements OnInit {
    places: PlaceModel[];
    kioskPlaces$ = this.microstoreService.getPlaces();
    viewType = 'map';
    location: CoordsModel;
    center: CoordsModel;
    lastCenter: CoordsModel;
    isLoading = false;

    constructor(
        private alertCtrl: AlertController,
        private googleMapsService: GoogleMapsService,
        private toastService: ToastService,
        private locationService: LocationService,
        private modalService: ModalService,
        private microstoreService: MicrostoreApiResource,
        private cdr: ChangeDetectorRef
    ) {
        addIcons({ informationCircle, pin, locate });
    }

    async ngOnInit() {
        try {
            this.isLoading = true;
            this.cdr.markForCheck();
            const { latitude, longitude } =
                await this.locationService.getCurrentLocation2();

            this.location = {
                latitude,
                longitude,
            };

            this.center = { ...this.location };
            this.lastCenter = { ...this.location };
            this.isLoading = false;
            this.cdr.markForCheck();
        } catch (error) {
            this.toastService.show('Could not determine your location');
            this.center = miamiCoords;
            this.location = miamiCoords;
            this.isLoading = false;
            this.cdr.markForCheck();
        }
    }

    centerChanged(coords: CoordsModel) {
        this.center = coords;
        if (this.shouldReloadPlaces()) {
            this.loadPlaces();
        }
    }

    async loadPlaces() {
        this.isLoading = true;
        this.cdr.markForCheck();
        this.lastCenter = { ...this.center };
        this.googleMapsService
            .nearbySearch(this.center, this.location)
            .pipe(
                tap({
                    next: (places) => {
                        this.places = places.sort((a, b) => a.distance - b.distance);
                    },
                    error: (err) => {
                        this.toastService.show(err.message);
                    },
                }),
                finalize(() => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    reposition() {
        this.center = { ...this.location };
    }

    shouldReloadPlaces() {
        const distance = getDistanceMi(
            this.center.latitude,
            this.center.longitude,
            this.lastCenter.latitude,
            this.lastCenter.longitude
        );
        return distance > 1;
    }

    showReposition() {
        return (
            this.center.latitude.toFixed(3) !== this.location.latitude.toFixed(3) ||
            this.center.longitude.toFixed(3) !== this.location.longitude.toFixed(3)
        );
    }

    showGoogleBusinessInfo() {
        this.alertCtrl
            .create({
                message:
                    new IonicSafeString(`The Cigar Scanner Places tool is powered by Google My Business. If a cigar store does not appear here,
        please contact the store’s owner and ask them to register it by contacting Google My Business at
        <a target="_blank" href="https://business.google.com/create">business.google.com</a>
        or <a href="tel:1-844-491-9665">844-491-9665</a>`),
                buttons: [
                    {
                        text: 'Ok',
                    },
                ],
            })
            .then((alert) => {
                alert.present();
            });
    }

    async openPlaceModal(place: PlaceModel) {
        const modal = await this.modalService.createCSModal(PlaceModalComponent, {
            place,
        });
        await modal.present();
    }
}
