import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { KioskCartModel } from '../../models/kiosk-cart.model';

import { KioskUserModel } from '../../models/kiosk-user.model';
import { KioskModel } from '../../models/kiosk.model';
import { LoaderService } from '../../services/loader.service';
import { KioskPlaceModel } from '../../models/kiosk-place.model';
import { PlaceModel } from '../../models/place.model';

@Injectable({
  providedIn: 'root'
})
export class MicrostoreApiResource {
  kiosks: KioskModel[];
  carts: KioskCartModel[];
  orders: KioskCartModel[];
  activeKiosk: KioskModel;

  constructor(
    private http: HttpClient,
    private loader: LoaderService
  ) { }

  getKiosks() {
    if (this.kiosks) {
      return of(this.kiosks);
    }

    this.loader.show();
    return this.http.get<KioskModel[]>('kiosks')
      .pipe(tap(kiosks => {
        this.loader.hide();
        this.kiosks = kiosks;
        if (!this.activeKiosk) {
          this.activeKiosk = this.kiosks[0];
        }
      }));
  }

  getActiveKiosk() {
    return this.activeKiosk;
  }

  setActiveKiosk(id: number) {
    const kiosk = this.kiosks.find(k => k.Id === id);
    this.activeKiosk = kiosk;
  }

  updateKioskSettings(kiosk: KioskModel) {
    return this.http.put<KioskModel>(`kiosks/${kiosk.Id}`,
      {
        MinOrder: kiosk.MinOrder,
        Discount: kiosk.Discount
      })
      .pipe(tap(kioskResponse => {
        kiosk = kioskResponse;
      }));
  }

  updateUser(user: KioskUserModel) {
    return this.http.put<KioskUserModel>(`kiosks/${user.KioskId}/users/${user.UserId}`,
      {
        IsEnabled: user.IsEnabled,
        IsAdmin: user.IsAdmin
      })
      .pipe(tap(userResponse => {
        user = userResponse;
      }));
  }

  deleteUser(user: KioskUserModel) {
    return this.http.delete<KioskUserModel>(`kiosks/${user.KioskId}/users/${user.UserId}`).pipe(map(() => {
      const kiosk = this.kiosks.find(k => k.Id === user.KioskId);
      kiosk.Users = kiosk.Users.filter(u => u.UserId !== user.UserId);
    }));
  }

  getPastOrder(id: string) {
    return this.orders.find(o => o.Id === parseInt(id, 10));
  }

  getOrder(id: string) {
    return this.carts.find(o => o.Id === parseInt(id, 10));
  }

  getCarts(id: number, showLoader) {
    if (showLoader) {
      this.loader.show();
    }
    return this.http.get<KioskCartModel[]>(`kiosks/${id}/carts`)
      .pipe(tap(carts => {
        this.loader.hide();
        this.carts = carts;
      }));
  }

  approveOrder(cardId: number) {
    return this.http.post(`kiosks/${this.kiosks[0].Id}/carts/${cardId}/approve`, {})
      .pipe(tap(() => {
        for (let i = 0; i < this.carts.length; i++) {
          if (this.carts[i].Id === cardId) {
            this.carts.splice(i, 1);
          }
        }
      }));
  }

  getPastOrders(id: number) {
    return this.http.get<KioskCartModel[]>(`kiosks/${id}/orders`)
      .pipe(tap(orders => {
        this.orders = orders;
      }));
  }

  getPlaces() {
    return this.http.get<KioskPlaceModel[]>('kiosks/locations').pipe(map(kioskPlaces => {
      return kioskPlaces.map<PlaceModel>(kp => {
        return {
          placeId: kp.LocationId,
          name: kp.Name,
          location: {
            latitude: kp.LocationLatitude,
            longitude: kp.LocationLongitude
          }
        } as PlaceModel;
      });
    }));
  }
}
