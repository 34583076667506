import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Observable, Observer } from 'rxjs';

import { environment } from '@environments/environment';
import { CoordsModel } from '../models/coords.model';
import { PlaceModel } from '../models/place.model';
import { getDistanceMi } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private promise: Promise<void>;
  private service: google.maps.places.PlacesService;

  constructor() {}

  loadScript() {
    if (!this.promise) {
      this.promise = new Promise((resolve) => {
        window['initMap'] = resolve;

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places&callback=initMap`;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      });
    }

    return this.promise;
  }

  initService(map: google.maps.Map) {
    this.service = new google.maps.places.PlacesService(map);
  }

  nearbySearch(mapCenter: CoordsModel, location: CoordsModel) {
    return new Observable((o: Observer<PlaceModel[]>) => {
      if (!this.service) {
        return o.error(new Error('PlacesService not defined'));
      }

      this.service.nearbySearch(
        {
          location: new google.maps.LatLng(
            mapCenter.latitude,
            mapCenter.longitude
          ),
          rankBy: google.maps.places.RankBy.DISTANCE,
          keyword: 'cigar store',
        },
        (results, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return o.error(new Error('PlacesService: Status is not OK'));
          }

          const places = results.map((p) => {
            return {
              placeId: p.place_id,
              name: p.name,
              rating: p.rating,
              vicinity: p.vicinity,
              location: {
                latitude: p.geometry.location.lat(),
                longitude: p.geometry.location.lng(),
              },
              distance: getDistanceMi(
                location.latitude,
                location.longitude,
                p.geometry.location.lat(),
                p.geometry.location.lng()
              ),
            } as PlaceModel;
          });

          o.next(places);
          return o.complete();
        }
      );
    });
  }

  placeDetails(placeId: string) {
    if (!this.service) {
      throw new Error('PlacesService not defined');
    }

    return new Promise<PlaceModel>((res, rej) => {
      Geolocation.getCurrentPosition().then((resp) => {
        this.service.getDetails(
          {
            placeId: placeId,
            fields: [
              'formatted_address',
              'geometry',
              'name',
              'business_status',
              'photo',
              'place_id',
              'vicinity',
              'international_phone_number',
              'rating',
              'url',
            ],
          },
          (result, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              rej(new Error('PlacesService: Status is not OK'));
            }

            const place = {
              placeId: result.place_id,
              name: result.name,
              rating: result.rating,
              vicinity: result.vicinity,
              location: {
                latitude: result.geometry.location.lat(),
                longitude: result.geometry.location.lng(),
              },
              distance: getDistanceMi(
                resp.coords.latitude,
                resp.coords.longitude,
                result.geometry.location.lat(),
                result.geometry.location.lng()
              ),
              phone: result.international_phone_number,
              addressParts: result.formatted_address.split(', '),
              url: result.url,
              photoUrls: result.photos
                ? result.photos.map((p) => p.getUrl({ maxHeight: 1000 }))
                : null,
            } as PlaceModel;
            res(place);
          }
        );
      }, rej);
    });
  }
}
