import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent } from "@ionic/angular/standalone";

@Component({
    selector: 'about',
    standalone: true,
    imports: [CommonModule, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent],
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/tools"></ion-back-button>
        </ion-buttons>
        <ion-title>About us</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">About us</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="prose dark:prose-invert m-auto text-center">
        <img
          class="object-cover w-96 m-auto"
          src="assets/images/about-logo.jpg"
        />
        <h3 class="">The Cigar Lab Co.</h3>
        <h4><i>Handmade Technology for Cigar Aficionados</i></h4>
        <p>
          Most of the content, descriptions and images are courtesy of
          <a (click)="openSite()"><em>NeptuneCigar.com</em></a
          >. Cigar Recognition System by <em>The Cigar Lab Corp</em>, Patent
          #9,519,962<br />
        </p>
        <p>&copy; {{ currentDate | date : 'yyyy' }} The Cigar Lab Co.</p>
      </div>
    </ion-content>
  `,
})
export class AboutPage implements OnInit {
    currentDate: Date = new Date();

    constructor() { }

    ngOnInit() { }

    openSite() {
        // we do not want to open this in app but use the default browser
        window.open(
            'https://www.neptunecigar.com?src=CigarScanner',
            '_blank',
            'location=yes'
        );
    }
}
