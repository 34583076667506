import { startWith } from 'rxjs/operators';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CoordsModel } from '@shared/models/coords.model';
import { PlaceModel } from '@shared/models/place.model';
import { GoogleMapsService } from '@shared/services/google-maps.service';

import { darkStyle, lightStyle } from './map-style';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'cs-map',
  standalone: true,
  imports: [CommonModule],
  template: ` <div #mapDiv></div> `,
  styles: [
    `
      div {
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  map: google.maps.Map;
  styles = this.doc.querySelector('ion-app').classList.contains('dark-theme')
    ? (darkStyle as google.maps.MapTypeStyle[])
    : (lightStyle as google.maps.MapTypeStyle[]);

  @ViewChild('mapDiv') mapDiv: ElementRef;
  @Input() center: CoordsModel;
  @Input() places: PlaceModel[];
  @Input() kioskPlaces: PlaceModel[];
  @Input() zoom: number;
  @Input() gestureHandling: 'cooperative' | 'greedy' | 'none' | 'auto' = 'auto';
  @Output() initiated = new EventEmitter<google.maps.Map>();
  @Output() centerChanged = new EventEmitter<CoordsModel>();
  @Output() idle = new EventEmitter<void>();
  @Output() placeClick = new EventEmitter<PlaceModel>();
  private markers: google.maps.Marker[] = [];
  center$: BehaviorSubject<CoordsModel>;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private googleMapsService: GoogleMapsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.googleMapsService.loadScript().then(() => this.initMap());
  }

  initMap() {
    const center = new google.maps.LatLng(
      this.center.latitude,
      this.center.longitude
    );

    this.center$ = new BehaviorSubject<CoordsModel>(this.center);
    this.center$.pipe(debounceTime(500)).subscribe((center) => {
      this.centerChanged.emit(center);
    });

    this.map = new google.maps.Map(this.mapDiv.nativeElement, {
      center: center,
      zoom: this.zoom,
      zoomControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      styles: this.styles,
      gestureHandling: this.gestureHandling,
    });

    this.map.addListener('center_changed', () => {
      const newCenter = this.map.getCenter();
      this.center$.next({
        latitude: newCenter.lat(),
        longitude: newCenter.lng(),
      });
    });
    this.map.addListener('idle', () => {
      this.idle.emit();
    });
    // this.map.addListener('tilesloaded', () => {});

    // google.maps.event.addListenerOnce(this.map, 'idle', function () {
    //   // do something only the first time the map is loaded
    //   console.log('addListenerOnce idle');
    // });

    this.loadKioskMarkers();
    this.loadMarkers();

    this.googleMapsService.initService(this.map);
    this.initiated.emit(this.map);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.map) {
      return;
    }

    if (changes['kioskPlaces']) {
      this.loadKioskMarkers();
    }

    if (changes['places']) {
      this.loadMarkers();
    }

    if (changes['center']) {
      const center = this.map.getCenter();
      if (
        center.lat() !== this.center.latitude ||
        center.lng() !== this.center.longitude
      ) {
        this.map.setCenter(
          new google.maps.LatLng(this.center.latitude, this.center.longitude)
        );
      }
    }
  }

  loadKioskMarkers() {
    if (!this.kioskPlaces) {
      return;
    }

    for (const place of this.kioskPlaces) {
      const marker = new google.maps.Marker({
        icon: './assets/images/pin-leaf.png',
        position: new google.maps.LatLng(
          place.location.latitude,
          place.location.longitude
        ),
        map: this.map,
      });

      marker.addListener('click', () => this.placeClick.emit(place));
    }
  }

  loadMarkers() {
    if (!this.places) {
      return;
    }

    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers.length = 0;

    for (const place of this.places) {
      if (
        this.kioskPlaces &&
        this.kioskPlaces.find((p) => p.placeId === place.placeId)
      ) {
        continue;
      }

      const marker = new google.maps.Marker({
        icon: './assets/images/pin.svg',
        position: new google.maps.LatLng(
          place.location.latitude,
          place.location.longitude
        ),
        map: this.map,
      });

      marker.addListener('click', () => this.placeClick.emit(place));

      this.markers.push(marker);
    }
  }
}
