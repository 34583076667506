<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/tabs/tools"></ion-back-button>
    </ion-buttons>
    <ion-title>Places</ion-title>
    <ion-buttons slot="end">
      <ion-button class="google-business" (click)="showGoogleBusinessInfo()">
        <ion-icon slot="icon-only" name="information-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="segments"
    ><ion-segment [(ngModel)]="viewType">
      <ion-segment-button value="map"> Map </ion-segment-button>
      <ion-segment-button value="list"> List </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading"> </ion-progress-bar>
</ion-header>

<ion-content
  class="places-wrapper"
  [ngClass]="{ 'showing-list': viewType == 'list' }"
>
  <div
    class="button-wrapper"
    [ngClass]="{ 'showing-list': viewType == 'list' }"
  ></div>

  <div class="map-wrapper" *ngIf="center">
    <ion-list [hidden]="viewType != 'map'">
      <cs-map
        [center]="center"
        [kioskPlaces]="kioskPlaces$ | async"
        [places]="places"
        [zoom]="11"
        (initiated)="loadPlaces()"
        (centerChanged)="centerChanged($event)"
        (placeClick)="openPlaceModal($event)"
      />
    </ion-list>

    <ion-list lines="full" class="list-wrapper" [hidden]="viewType != 'list'">
      <ion-item *ngFor="let place of places" (click)="openPlaceModal(place)">
        <ion-label class="ion-text-wrap">
          <h2>{{ place.name }}</h2>
          <h3 *ngIf="place.vicinity">{{ place.vicinity }}</h3>
          <div class="distance-wrapper">
            <ion-icon name="pin"></ion-icon>
            <div class="distance">
              {{ place.distance | number : '.2-2' }}mi away
            </div>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="map-buttons" [hidden]="viewType != 'map'">
      <div>
        <!-- <div *ngIf="showRefresh()" (click)="loadPlaces()">
          <ion-icon name="refresh"></ion-icon>
        </div> -->
      </div>
      <div>
        <div *ngIf="showReposition()" (click)="reposition()">
          <ion-icon name="locate"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</ion-content>
