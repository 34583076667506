<ion-header>
  <ion-toolbar>
    <ion-title>{{ place.name }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" color="dark">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="h-[50vh]">
    <cs-map
      [center]="place.location"
      [places]="[place]"
      [zoom]="17"
      gestureHandling="none"
    />
  </div>
  <ion-list lines="full">
    <ion-item>
      <ion-icon name="pin" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap"
        ><a class="location" [href]="sanitizedUrl" target="_system">
          <div>
            <b>{{ place.name }}</b>
          </div>
          <div *ngFor="let part of place.addressParts">{{ part }}</div>
        </a></ion-label
      >
    </ion-item>
    <ion-item>
      <ion-icon name="navigate" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap"
        >{{ place.distance | number : '.2-2' }}mi away</ion-label
      >
    </ion-item>
    <ion-item *ngIf="place.rating">
      <ion-icon name="star" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap"
        ><span>{{ place.rating }}</span
        >/5</ion-label
      >
    </ion-item>
    <ion-item *ngIf="place.phone">
      <ion-icon name="call" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap">
        <a href="tel:{{ place.phone }}">{{ place.phone }}</a>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="isLoading"
      ><ion-thumbnail style="width: 24px; height: 24px" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label>
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ng-container *ngIf="place.photoUrls">
      <ion-item-divider>
        <ion-label> Photos ({{ place.photoUrls.length }})</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-label>
          <swiper-container pagination="true" pagination-clickable="true">
            <swiper-slide *ngFor="let photoUrl of place.photoUrls">
              <img imgModal [src]="photoUrl" loading="lazy" />
            </swiper-slide>
          </swiper-container>
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
