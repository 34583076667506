import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImgModalDirective } from '@modals/img-modal/img-modal.directive';
import { ModalService } from '@services/modal.service';
import { MapComponent } from '@shared/components/map/map';
import { PlaceModel } from '@shared/models/place.model';
import { GoogleMapsService } from '@shared/services/google-maps.service';
import { addIcons } from "ionicons";
import { closeOutline, pin, navigate, star, call } from "ionicons/icons";
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItem, IonLabel, IonThumbnail, IonSkeletonText, IonItemDivider } from "@ionic/angular/standalone";

@Component({
    selector: 'place',
    standalone: true,
    imports: [CommonModule, MapComponent, ImgModalDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItem, IonLabel, IonThumbnail, IonSkeletonText, IonItemDivider],
    templateUrl: 'place.html',
    styleUrls: ['place.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceModalComponent {
    @Input() place: PlaceModel;
    sanitizedUrl: SafeUrl;
    isLoading = true;

    constructor(
        private googleMapsService: GoogleMapsService,
        private sanitizer: DomSanitizer,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef
    ) {
        addIcons({ closeOutline, pin, navigate, star, call });
    }

    async ionViewDidEnter() {
        try {
            this.place = await this.googleMapsService.placeDetails(
                this.place.placeId
            );
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.place.url);
            this.isLoading = false;
            this.cdr.markForCheck();
        } catch (error) {
            this.isLoading = false;
            this.cdr.markForCheck();
            console.error({ error });
        }
    }

    dismiss() {
        this.modalService.dismiss();
    }
}
